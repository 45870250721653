import s from '../MultiOfferings.st.css';
import { OfferingViewModelFactory } from '../../../domain/offering-view-model-factory';
import OfferingView from '../../OfferingView/OfferingView';
import { translate } from 'react-i18next';
import { withRunningEnvironmentContext } from '../../context/running-environment-context';
import * as React from 'react';

const OfferingListComponent = props => {
  const { t, displayOptions, offeringsDomain, runningEnvironment } = props;
  const { isMobile, isRTL } = runningEnvironment;
  return (
    <ul className={s.cardsContainer}>
      {offeringsDomain.map((offeringDomain, index) => {
        const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
          offeringDomain,
          displayOptions.offeringDisplayOptions,
          t,
          isRTL,
          isMobile,
        );
        return (
          <li
            className={s.cardContainer}
            key={`offering-${offeringViewModel.id}`}
          >
            <OfferingView offeringViewModel={offeringViewModel} />
          </li>
        );
      })}
    </ul>
  );
};

export const OfferingList = translate(null, { wait: true })(
  withRunningEnvironmentContext(OfferingListComponent),
);
