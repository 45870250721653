import {
  CategoriesDisplayOptions,
  MultiOfferingsDisplayOptions,
} from '../display-options/offering-list-widget-display-options';
import { AlignmentOptions } from '../../Shared/appKeys/SettingsKeys';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { VARIANT } from 'wix-ui-tpa/dist/src/components/Tabs/constants';

export interface IMultiOfferingsViewModel {
  title: TitleViewModel;
  layout: string;
  categories: CategoriesViewModel;
  rtl?: boolean; //todo - should be removed when yoshi will fix rtl!!!
}

export interface TitleViewModel {
  text: string;
  isVisible: boolean;
  alignment: AlignmentOptions;
}

export interface CategoriesViewModel {
  isVisible: boolean;
  alignment: AlignmentOptions;
  variant: string;
}

export enum ListLayoutOptions {
  COLUMN = 'COLUMN',
  GRID = 'GRID',
}

function getTitle(title, formatter: Function) {
  return title || formatter('multi-offerings.title');
}

//todo - should be removed when yoshi will fix rtl!!!
function getAlignment(isRTL, alignment) {
  return !isRTL || alignment === AlignmentOptions.CENTER
    ? alignment
    : alignment === AlignmentOptions.LEFT
    ? AlignmentOptions.RIGHT
    : AlignmentOptions.LEFT;
}

export class MultiOfferingsViewModelFactory {
  static createMultiOfferingsViewModel(
    multiOfferingsDisplayOptions: MultiOfferingsDisplayOptions,
    categories: OfferingCategoryDto[],
    formatter: Function,
    rtl: boolean, //todo - should be removed when yoshi will fix rtl!!!
  ): IMultiOfferingsViewModel {
    const titleView = {
      text: getTitle(multiOfferingsDisplayOptions.title, formatter),
      isVisible: multiOfferingsDisplayOptions.isTitleVisible,
      alignment: getAlignment(rtl, multiOfferingsDisplayOptions.titleAlignment), //todo - should be removed when yoshi will fix rtl!!!
    };

    const categoriesDisplayOptions: CategoriesDisplayOptions =
      multiOfferingsDisplayOptions.categoriesDisplayOptions;
    const categoriesView = {
      isVisible:
        categoriesDisplayOptions.isCategoriesVisible &&
        categories &&
        categories.length > 1,
      alignment: getAlignment(
        rtl,
        categoriesDisplayOptions.categoriesContentAlignment,
      ), //todo - should be removed when yoshi will fix rtl!!!
      variant: categoriesDisplayOptions.isCategoriesContentStretch
        ? VARIANT.fullWidth
        : VARIANT.fit,
    };

    const offeringListLayout = ListLayoutOptions.COLUMN;

    return {
      title: titleView,
      layout: offeringListLayout,
      categories: categoriesView,
    };
  }
}
