/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CropProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Crop: React.SFC<CropProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M40.02 34a2 2 0 0 1-2 2h-25a2 2 0 0 1-2-2V18a2 2 0 0 1 2-2h25a2 2 0 0 1 2 2v16z" className="p1" />
      <path d="M36.018 22.5L49.145 40H2.456l12.958-12.021c.944-.708 2.275-.836 3.004.094l3.328 5.425 11.251-10.919c.903-.761 2.241-.965 3.021-.079zm-24.481.044a5.62 5.62 0 1 1 .001-11.24 5.62 5.62 0 0 1-.001 11.24z"
        className="p2" />
      <path d="M17.158 16.924a5.622 5.622 0 0 1-5.621 5.62 5.54 5.54 0 0 1-.517-.026V18a2 2 0 0 1 2-2h4.055c.05.3.083.609.083.924zm15.839 5.655c.903-.76 2.24-.964 3.02-.08l4.003 5.336V34a2 2 0 0 1-2 2H19.167l2.579-2.502 11.25-10.92z"
        className="p3" />
      <path d="M13.02 36h6.146l2.58-2.502-3.328-5.425c-.729-.93-2.06-.802-3.004-.094l-4.394 4.076V34a2 2 0 0 0 2 2" className="p4" />
      <path d="M38.02 16h-25a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h25a2 2 0 0 0 2-2V18a2 2 0 0 0-2-2m0 1c.552 0 1 .449 1 1v16c0 .551-.448 1-1 1h-25c-.55 0-1-.449-1-1V18c0-.551.45-1 1-1h25"
        className="p5" />
    </g>
  </svg>
);
Crop.displayName = 'Crop';
/* tslint:enable */
/* eslint-enable */
