/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface Proportions40_60Props extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Proportions40_60: React.SFC<Proportions40_60Props> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "114" } height={ size || "51" } {...props}>
    <g transform="translate(12 15)" className="p1" fillRule="evenodd">
      <rect width="35" height="22" rx="3" />
      <rect x="37" width="53" height="22" rx="3" />
    </g>
  </svg>
);
Proportions40_60.displayName = 'Proportions40_60';
/* tslint:enable */
/* eslint-enable */
