import * as React from 'react';
import TpaModal from '../Modal/TpaModal';
import { Button } from 'wix-ui-tpa/Button';
import { Text } from 'wix-ui-tpa/Text';
import s from './UserMessage.st.css';
import { translate, TransProps } from 'react-i18next';

interface UserMessageProps {
  isOpen: boolean;
  onRequestClose: Function;
  isMobile: boolean;
}

class UserMessage extends React.PureComponent<UserMessageProps & TransProps> {
  constructor(props) {
    super(props);
    this.closeMessage = this.closeMessage.bind(this);
  }

  closeMessage() {
    const { onRequestClose } = this.props;
    onRequestClose();
  }

  render() {
    const { isOpen, isMobile, t } = this.props;

    return (
      <TpaModal
        isOpen={isOpen}
        onRequestClose={this.closeMessage}
        isMobile={isMobile}
      >
        <div className={s.root}>
          <Text {...s('message-text')} typography="runningText">
            {t('user-message.not-bookable')}
          </Text>
          <Button
            {...s('message-button')}
            onClick={this.closeMessage}
            data-hook="user-message-action-button"
            fullWidth={isMobile}
          >
            {t('user-message.action-ok-label')}
          </Button>
        </div>
      </TpaModal>
    );
  }
}

export default translate(null, { wait: true })(UserMessage);
