/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RectangleFilledProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const RectangleFilled: React.SFC<RectangleFilledProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <rect x="8" y="18" width="35" height="15" rx="2" className="p1" fillRule="evenodd" />
  </svg>
);
RectangleFilled.displayName = 'RectangleFilled';
/* tslint:enable */
/* eslint-enable */
