/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface BookingsProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Bookings: React.SFC<BookingsProps> = ({size, ...props}) => (
  <svg viewBox="0 0 60 60" fill="currentColor" width={ size || "60" } height={ size || "60" } {...props}>
    <g fill="none">
      <circle cx="30" cy="30" r="30" fill="currentColor" />
      <path d="M20.35 39.5h20.3a2.5 2.5 0 0 1-2.5 2.5h-15.3a2.5 2.5 0 0 1-2.5-2.5zM25.15 17a1.5 1.5 0 0 1 1.5 1.5V20h-3v-1.5a1.5 1.5 0 0 1 1.5-1.5zM35.15 17a1.5 1.5 0 0 1 1.5 1.5V20h-3v-1.5a1.5 1.5 0 0 1 1.5-1.5zM34.478 31h7.172l1.27 4.45A2 2 0 0 1 41 38H20a2 2 0 0 1-1.92-2.55L19.35 31h7.522v3h1.704v-3h4.241a11.46 11.46 0 0 0-.64 3H34c.008-.56.072-1.164.192-1.812.074-.399.17-.795.286-1.188zM33.47 29.5h-4.894v-3.9H27.22c-.048.32-.146.588-.294.804a1.74 1.74 0 0 1-.558.522 2.305 2.305 0 0 1-.75.276 4.034 4.034 0 0 1-.858.066v1.284h2.112v.948H19.35V23a3 3 0 0 1 3-3h1.3v.5a1.5 1.5 0 0 0 3 0V20h7v.5a1.5 1.5 0 0 0 3 0V20h2a3 3 0 0 1 2.96 3v6.5h-6.573c.162-.352.342-.692.541-1.02.34-.56.73-1.032 1.17-1.416V25.6h-5.736v1.584h3.984A12.804 12.804 0 0 0 33.47 29.5z"
        fill="currentColor" />
    </g>
  </svg>
);
Bookings.displayName = 'Bookings';
/* tslint:enable */
/* eslint-enable */
