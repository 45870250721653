/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface AlignCenterProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const AlignCenter: React.SFC<AlignCenterProps> = ({size, ...props}) => (
  <svg viewBox="0 0 38 38" fill="currentColor" width={ size || "38" } height={ size || "38" } {...props}>
    <path d="M25.000,27.000 C25.000,27.000 20.000,27.000 20.000,27.000 C20.000,27.000 20.000,29.000 20.000,29.000 C20.000,29.000 18.000,29.000 18.000,29.000 C18.000,29.000 18.000,27.000 18.000,27.000 C18.000,27.000 13.000,27.000 13.000,27.000 C11.852,27.000 11.000,26.000 11.000,25.000 C11.000,25.000 11.000,22.000 11.000,22.000 C11.000,21.000 11.852,20.000 13.000,20.000 C13.000,20.000 18.000,20.000 18.000,20.000 C18.000,20.000 18.000,9.000 18.000,9.000 C18.000,9.000 20.000,9.000 20.000,9.000 C20.000,9.000 20.000,20.000 20.000,20.000 C20.000,20.000 25.420,20.000 25.420,20.000 C26.569,20.000 27.000,21.000 27.000,22.000 C27.000,22.000 27.000,25.000 27.000,25.000 C27.000,26.000 26.149,27.000 25.000,27.000 Z"
      className="cls-b3" fillRule="evenodd" />
    <path d="M24.000,16.000 C24.000,17.105 23.105,18.000 22.000,18.000 C22.000,18.000 16.000,18.000 16.000,18.000 C14.895,18.000 14.000,17.105 14.000,16.000 C14.000,16.000 14.000,13.000 14.000,13.000 C14.000,11.895 14.895,11.000 16.000,11.000 C16.000,11.000 22.000,11.000 22.000,11.000 C23.105,11.000 24.000,11.895 24.000,13.000 C24.000,13.000 24.000,16.000 24.000,16.000 Z"
      className="cls-b1" fillRule="evenodd" />
  </svg>
);
AlignCenter.displayName = 'AlignCenter';
/* tslint:enable */
/* eslint-enable */
