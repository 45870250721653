/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface FitProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Fit: React.SFC<FitProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M12 16.02v-3c0-.55.449-1 1-1h25c.552 0 1 .45 1 1v3H12zm27 23c0 .551-.448 1-1 1H13c-.551 0-1-.449-1-1v-3h27v3zm-1-28H13a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h25a2 2 0 0 0 2-2v-26a2 2 0 0 0-2-2z"
        className="p1" />
      <path d="M25 32.526l-3.358-5.059a1 1 0 0 0-1.483-.207L14 32.526h11z" opacity=".6" className="p2" />
      <path d="M36.992 32.544H22l8.715-7.291a1 1 0 0 1 1.469.206l4.808 7.085zM20 21.02a2 2 0 1 1-4.001-.001A2 2 0 0 1 20 21.02z" className="p3" />
    </g>
  </svg>
);
Fit.displayName = 'Fit';
/* tslint:enable */
/* eslint-enable */
