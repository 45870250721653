/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RadiusOutlineProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const RadiusOutline: React.SFC<RadiusOutlineProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <path d="M14.5 20a5.5 5.5 0 0 0 0 11h22a5.5 5.5 0 0 0 0-11h-22zm0-2h22a7.5 7.5 0 0 1 0 15h-22a7.5 7.5 0 0 1 0-15z" fill="currentColor" className="p1" />
  </svg>
);
RadiusOutline.displayName = 'RadiusOutline';
/* tslint:enable */
/* eslint-enable */
