/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CloseIconProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const CloseIcon: React.SFC<CloseIconProps> = ({size, ...props}) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width={ size || "24" } height={ size || "24" } {...props}>
    <defs>
      <path d="M12,11.0769231 L17.0769231,6 L18,6.92307692 L12.9230769,12 L18,17.0769231 L17.0769231,18 L12,12.9230769 L6.92307692,18 L6,17.0769231 L11.0769231,12 L6,6.92307692 L6.92307692,6 L12,11.0769231 Z"
        id="path-1" />
    </defs>
    <g id="x" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="Icons-/-Close-/-L">
        <mask id="mask-2" fill="currentColor">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" fill="currentColor" fillRule="evenodd" xlinkHref="#path-1" />
      </g>
    </g>
  </svg>
);
CloseIcon.displayName = 'CloseIcon';
/* tslint:enable */
/* eslint-enable */
