const hexToRGBA = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const calculateOpacity = opacity => {
  if (opacity === 0) {
    return 0;
  }
  return opacity || 100;
};

export const isColor = data => data && !!data.themeName;
export const overrideSiteColorIfNeeded = (color, siteColors) => {
  if (isColor(color)) {
    const siteColor = siteColors.find(
      ({ reference }) => reference === color.themeName,
    );

    const overloadedColor = {
      reference: color.themeName,
      value: hexToRGBA(siteColor.value, calculateOpacity(color.opacity)),
    };
    return overloadedColor;
  }
  return color;
};
