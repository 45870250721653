/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface OverlappingProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Overlapping: React.SFC<OverlappingProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "72" } height={ size || "72" } {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M35 16h23a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H35a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2zm0 28h23a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H35a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2z" className="p1"
      />
      <path d="M42 16h-7a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h7v2a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2h26a2 2 0 0 1 2 2v2zm-6 3h8v2h-8v-2zm0 4h21v2H36v-2zm6 21h-7a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h7v2a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2V42a2 2 0 0 1 2-2h26a2 2 0 0 1 2 2v2zm-6 3h8v2h-8v-2zm0 4h21v2H36v-2z"
        className="p2" />
      <path d="M28 29H15l6.718-5.797c.466-.295 1.17-.27 1.55.086L28 29zM16.5 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM28 57H15l6.718-5.797c.466-.295 1.17-.27 1.55.086L28 57zM16.5 46a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        className="p3" />
      <path d="M33 20.121V26a2 2 0 0 0 2 2h2.451L38 29H23l8.917-8.79A.989.989 0 0 1 33 20.122zm0 28V54a2 2 0 0 0 2 2h2.451L38 57H23l8.917-8.79A.989.989 0 0 1 33 48.122z"
        className="p4" />
    </g>
  </svg>
);
Overlapping.displayName = 'Overlapping';
/* tslint:enable */
/* eslint-enable */
