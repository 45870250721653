import * as React from 'react';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { CategoriesViewModel } from '../../../domain/multi-offerings-view-model-factory';
import { Tabs } from 'wix-ui-tpa/Tabs';
import s from './CategoryList.st.css';
import { SKIN } from 'wix-ui-tpa/dist/src/components/Tabs/constants';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface CategoryListProps extends RunningEnvironmentProps {
  categories: OfferingCategoryDto[];
  categoriesViewModel: CategoriesViewModel;
  onCategorySelected: Function;
  selectedCategoryIndex: number;
}

class CategoryListComponent extends React.PureComponent<CategoryListProps> {
  constructor(props: CategoryListProps) {
    super(props);
    this.onCategoryTabSelected = this.onCategoryTabSelected.bind(this);
  }

  onCategoryTabSelected(newSelectedCategoryIndex) {
    this.props.onCategorySelected(newSelectedCategoryIndex);
  }

  render() {
    const {
      categories,
      categoriesViewModel,
      selectedCategoryIndex,
      runningEnvironment,
    } = this.props;
    const items = categories.map(category => {
      return {
        title: category.name,
      };
    });
    const tabsProps = {
      items,
      onTabClick: newSelectedCategoryIndex =>
        this.onCategoryTabSelected(newSelectedCategoryIndex),
      activeTabIndex: selectedCategoryIndex,
      skin: SKIN.fitUnderline,
      alignment: categoriesViewModel.alignment,
      variant: categoriesViewModel.variant,
    };
    const { isMobile } = runningEnvironment;

    return (
      <Tabs
        data-hook="offerings-categories"
        {...tabsProps}
        {...s('root', { isMobile, disableFontSize: isMobile }, tabsProps)}
      />
    );
  }
}

export const CategoryList = withRunningEnvironmentContext(
  CategoryListComponent,
);
