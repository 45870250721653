import * as React from 'react';
import * as ReactModal from 'react-modal';
import s from './TpaModal.st.css';
import { CloseIcon } from '../../../assets/icons/dist';

interface TpaModalProps {
  isOpen: boolean;
  onRequestClose: Function;
  isMobile: boolean;
}

interface TpaModalState {
  isRendered: boolean;
}

export default class TpaModal extends React.PureComponent<
  TpaModalProps,
  TpaModalState
> {
  private readonly containerRef;
  constructor(props) {
    super(props);
    this.containerRef = React.createRef<HTMLDivElement>();
    this.getParentNode = this.getParentNode.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      isRendered: false,
    };
  }

  closeModal() {
    const { onRequestClose } = this.props;
    onRequestClose();
  }

  getParentNode() {
    return this.containerRef.current;
  }

  componentDidMount() {
    this.setState({
      isRendered: true,
    });
  }

  render() {
    const { isOpen, children, isMobile } = this.props;
    const { isRendered } = this.state;
    return (
      <div
        ref={this.containerRef}
        {...s('root', { mobile: isMobile })}
        data-hook="react-modal-container"
      >
        {isRendered ? (
          <ReactModal
            isOpen={isOpen}
            className={s.modal}
            onRequestClose={this.closeModal}
            overlayClassName={s.overlay}
            parentSelector={this.getParentNode}
            ariaHideApp={false}
          >
            <div
              className={s.closeButton}
              onClick={this.closeModal}
              data-hook="modal-close-button"
            >
              <CloseIcon />
            </div>
            {children}
          </ReactModal>
        ) : null}
      </div>
    );
  }
}
