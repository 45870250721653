/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface AlignLeftProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const AlignLeft: React.SFC<AlignLeftProps> = ({size, ...props}) => (
  <svg viewBox="0 0 38 38" fill="currentColor" width={ size || "38" } height={ size || "38" } {...props}>
    <path d="M26.000,27.000 C26.000,27.000 17.000,27.000 17.000,27.000 C15.852,27.000 15.000,26.000 15.000,25.000 C15.000,25.000 15.000,22.000 15.000,22.000 C15.000,21.000 15.852,20.000 17.000,20.000 C17.000,20.000 26.420,20.000 26.420,20.000 C27.569,20.000 28.000,21.000 28.000,22.000 C28.000,22.000 28.000,25.000 28.000,25.000 C28.000,26.000 27.149,27.000 26.000,27.000 ZM11.000,9.000 C11.000,9.000 13.000,9.000 13.000,9.000 C13.000,9.000 13.000,29.000 13.000,29.000 C13.000,29.000 11.000,29.000 11.000,29.000 C11.000,29.000 11.000,9.000 11.000,9.000 Z"
      className="cls-b3" fillRule="evenodd" />
    <path d="M25.000,16.000 C25.000,17.105 24.105,18.000 23.000,18.000 C23.000,18.000 17.000,18.000 17.000,18.000 C15.896,18.000 15.000,17.105 15.000,16.000 C15.000,16.000 15.000,13.000 15.000,13.000 C15.000,11.895 15.896,11.000 17.000,11.000 C17.000,11.000 23.000,11.000 23.000,11.000 C24.105,11.000 25.000,11.895 25.000,13.000 C25.000,13.000 25.000,16.000 25.000,16.000 Z"
      className="cls-b1" fillRule="evenodd" />
  </svg>
);
AlignLeft.displayName = 'AlignLeft';
/* tslint:enable */
/* eslint-enable */
