import * as React from 'react';
import i18n from '../../i18n';
import { I18nextProvider } from 'react-i18next';
import { MultiOfferings } from '../MultiOfferings/MultiOfferings';
import { OfferingDomain } from '../../domain/offering-domain';
import {
  getDisplayOptions,
  OfferingListWidgetDisplayOptions,
} from '../../display-options/offering-list-widget-display-options';
import {
  MOBILE_MULTI_OFFERINGS_MIN_WIDTH,
  MULTI_OFFERINGS_MIN_WIDTH,
} from './WidgetApp.const';
import {
  BiLoggerContext,
  BiLoggerContextProvider,
} from '../context/bi-logger-context';
import { BiLoggerDriver } from '../../adapters/reporting/bi-logger/bi-logger-driver';
import { ISantaProps } from '@wix/native-components-infra/dist/es/src/types/types';
import { CatalogOfferingDto } from '@wix/bookings-uou-domain/dist/src';
import EmptyStateView from '../EmptyStateView/EmptyStateView';
import { FormFactor } from '../../constants/environment.const';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { NavigationDriver } from '../../platform/navigation/navigation-driver';
import UserMessage from '../UserMessage/UserMessage';
import {
  OfferingCallbacksContext,
  OfferingCallbacksProvider,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentContext,
  RunningEnvironmentContextProvider,
} from '../context/running-environment-context';
import { setupMomentLocales } from '../../platform/setups/moment';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';

interface WidgetViewerMainProps extends WidgetAppProps {
  host: ISantaProps;
}

export interface PlatformContext {
  isDummyMode: boolean;
  isRTL: boolean;
  isEditorMode: boolean;
  isSSR: boolean;
}

interface WidgetAppProps {
  offerings: CatalogOfferingDto[];
  categories: OfferingCategoryDto[];
  locale: string;
  translations: any;
  settingsData: any;
  setContainerHeight: any;
  setContainerDimensions: any;
  biLoggerDriver: BiLoggerDriver;
  navigationDriver: NavigationDriver;
  canReportLoading: boolean;
  userMessage: UserMessageDriver;
  platformContext: PlatformContext;
  appLoadedCallback: Function;
}

export class WidgetApp extends React.PureComponent<WidgetViewerMainProps> {
  private isComponentLoaded = false;
  private readonly widgetAppRef;
  private isExecuteOnLoadedRequired = true;

  constructor(props) {
    super(props);
    this.widgetAppRef = React.createRef();
  }

  getRef() {
    return this.widgetAppRef;
  }

  private getMultiOfferingsHeight(): number {
    if (this.getRef().current.getClientRects()[0]) {
      return this.getRef().current.getClientRects()[0].height;
    }
    return 0;
  }

  private updateHeight() {
    const { setContainerHeight } = this.props;
    const { dimensions } = this.props.host;

    if (dimensions && dimensions.height !== this.getMultiOfferingsHeight()) {
      setContainerHeight(this.getMultiOfferingsHeight());
    }
  }

  shouldDisplayOfferings() {
    const { offerings } = this.props;
    return offerings.length > 0;
  }

  componentDidUpdate() {
    const { setContainerDimensions } = this.props;
    const { dimensions, formFactor } = this.props.host;
    if (formFactor !== FormFactor.MOBILE) {
      if (dimensions && dimensions.width < MULTI_OFFERINGS_MIN_WIDTH) {
        setContainerDimensions(MULTI_OFFERINGS_MIN_WIDTH, dimensions.height);
      }
    } else if (
      dimensions &&
      dimensions.width < MOBILE_MULTI_OFFERINGS_MIN_WIDTH
    ) {
      setContainerDimensions(
        MOBILE_MULTI_OFFERINGS_MIN_WIDTH,
        dimensions.height,
      );
    }

    this.updateHeight();
    this.executeOnLoadedCallbacks();
  }

  componentDidMount() {
    this.updateHeight();
    this.isComponentLoaded = true;
    this.executeOnLoadedCallbacks();
  }

  executeOnLoadedCallbacks() {
    const {
      offerings,
      biLoggerDriver,
      canReportLoading,
      appLoadedCallback,
    } = this.props;
    if (
      canReportLoading &&
      this.isExecuteOnLoadedRequired &&
      this.isComponentLoaded
    ) {
      this.isExecuteOnLoadedRequired = false;
      biLoggerDriver.sendViewerOpened(offerings.length);
      appLoadedCallback();
    }
  }

  createOfferingsDomainInstance = () => {
    return this.props.offerings.map(OfferingDomain.fromDto);
  };

  render() {
    const {
      locale,
      translations,
      settingsData,
      biLoggerDriver,
      navigationDriver,
      categories,
      userMessage,
      offerings,
      platformContext,
    } = this.props;
    const { formFactor } = this.props.host;

    setupMomentLocales(locale, translations);

    const displayOptions: OfferingListWidgetDisplayOptions = getDisplayOptions(
      settingsData,
    );
    const offeringsDomain = this.createOfferingsDomainInstance();

    const biLoggerContext: BiLoggerContext = {
      biLoggerDriver,
    };

    const offeringCallbacksContext: OfferingCallbacksContext = {
      offeringCallbacks: {
        onOfferingSelected: (offeringId, intent) =>
          navigationDriver.navigateToApp(
            offerings.find(offering => offering.id === offeringId),
            intent,
          ),
      },
    };
    const isMobile = formFactor === FormFactor.MOBILE;
    const runningEnvironmentContext: RunningEnvironmentContext = {
      isMobile,
      ...platformContext,
    };

    return (
      <div ref={this.widgetAppRef}>
        <I18nextProvider i18n={i18n(locale, translations)}>
          <BiLoggerContextProvider value={biLoggerContext}>
            <OfferingCallbacksProvider value={offeringCallbacksContext}>
              <RunningEnvironmentContextProvider
                value={runningEnvironmentContext}
              >
                <TPAComponentsProvider value={{ mobile: isMobile }}>
                  <UserMessage
                    isOpen={userMessage.shouldDisplayMessage}
                    onRequestClose={userMessage.closeMessage}
                    isMobile={isMobile}
                  />
                  {this.shouldDisplayOfferings() ? (
                    <MultiOfferings
                      offeringsDomain={offeringsDomain}
                      displayOptions={displayOptions}
                      categories={categories}
                    />
                  ) : (
                    <EmptyStateView />
                  )}
                </TPAComponentsProvider>
              </RunningEnvironmentContextProvider>
            </OfferingCallbacksProvider>
          </BiLoggerContextProvider>
        </I18nextProvider>
      </div>
    );
  }
}
