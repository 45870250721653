/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface Proportions50_50Props extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Proportions50_50: React.SFC<Proportions50_50Props> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "114" } height={ size || "51" } {...props}>
    <g transform="translate(12 15)" className="p1" fillRule="evenodd">
      <rect width="44" height="22" rx="3" />
      <rect x="46" width="44" height="22" rx="3" />
    </g>
  </svg>
);
Proportions50_50.displayName = 'Proportions50_50';
/* tslint:enable */
/* eslint-enable */
