export const isFont = data => data && !!data.editorKey;
export const overrideSiteFontIfNeeded = (font, siteTextPresets) => {
  if (isFont(font)) {
    const siteFont = Object.values(siteTextPresets).find(
      ({ editorKey }) => editorKey === font.editorKey,
    ) as any;
    const overloadedFont = {
      ...siteFont,
      ...font,
      family: siteFont.fontFamily,
    };
    overloadedFont.style = {
      bold: overloadedFont.weight === 'bold',
      italic: overloadedFont.style === 'italic',
    };
    return overloadedFont;
  }
  return font;
};
