/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RoundProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Round: React.SFC<RoundProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <circle cx="25.5" cy="25.5" r="14.5" className="p1" fillRule="evenodd" />
  </svg>
);
Round.displayName = 'Round';
/* tslint:enable */
/* eslint-enable */
