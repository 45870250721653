/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RectangleOutlineProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const RectangleOutline: React.SFC<RectangleOutlineProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <path d="M10 20v11h31V20H10zm0-2h31a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2z" className="p1" />
  </svg>
);
RectangleOutline.displayName = 'RectangleOutline';
/* tslint:enable */
/* eslint-enable */
