/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RadiusFilledProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const RadiusFilled: React.SFC<RadiusFilledProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "51" } height={ size || "51" } {...props}>
    <rect x="7" y="18" width="37" height="15" rx="7.5" className="p1" fillRule="evenodd" />
  </svg>
);
RadiusFilled.displayName = 'RadiusFilled';
/* tslint:enable */
/* eslint-enable */
