/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface SideBySideProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const SideBySide: React.SFC<SideBySideProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "72" } height={ size || "72" } {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M36 12h22a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H36V12zm0 17h22a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H36V29zm0 17h22a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H36V46z" className="p1"
      />
      <path d="M14 12h22v14H14a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2zm24 4h8v2h-8v-2zm0 4h18v2H38v-2zm-24 9h22v14H14a2 2 0 0 1-2-2V31a2 2 0 0 1 2-2zm24 4h8v2h-8v-2zm0 4h18v2H38v-2zm-24 9h22v14H14a2 2 0 0 1-2-2V48a2 2 0 0 1 2-2zm24 4h8v2h-8v-2zm0 4h18v2H38v-2z"
        className="p2" />
      <path d="M25 23H15l5.167-4.83c.359-.247.9-.226 1.193.07L25 23zm0 17H15l5.167-4.83c.359-.247.9-.226 1.193.07L25 40zm0 17H15l5.167-4.83c.359-.247.9-.226 1.193.07L25 57z"
        className="p3" />
      <path d="M33 23H21l7.133-6.836c.323-.245.805-.216 1.058.088L33 23zm-17.5-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM33 40H21l7.133-6.836c.323-.245.805-.216 1.058.088L33 40zm-17.5-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM33 57H21l7.133-6.836c.323-.245.805-.216 1.058.088L33 57zm-17.5-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        className="p4" />
    </g>
  </svg>
);
SideBySide.displayName = 'SideBySide';
/* tslint:enable */
/* eslint-enable */
