/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface AlignRightProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const AlignRight: React.SFC<AlignRightProps> = ({size, ...props}) => (
  <svg viewBox="0 0 38 38" fill="currentColor" width={ size || "38" } height={ size || "38" } {...props}>
    <path d="M26.000,29.000 C26.000,29.000 26.000,9.000 26.000,9.000 C26.000,9.000 28.000,9.000 28.000,9.000 C28.000,9.000 28.000,29.000 28.000,29.000 C28.000,29.000 26.000,29.000 26.000,29.000 ZM22.000,27.000 C22.000,27.000 13.000,27.000 13.000,27.000 C11.852,27.000 11.000,26.000 11.000,25.000 C11.000,25.000 11.000,22.000 11.000,22.000 C11.000,21.000 11.852,20.000 13.000,20.000 C13.000,20.000 22.420,20.000 22.420,20.000 C23.569,20.000 24.000,21.000 24.000,22.000 C24.000,22.000 24.000,25.000 24.000,25.000 C24.000,26.000 23.149,27.000 22.000,27.000 Z"
      className="cls-b3" fillRule="evenodd" />
    <path d="M24.000,16.000 C24.000,17.105 23.105,18.000 22.000,18.000 C22.000,18.000 16.000,18.000 16.000,18.000 C14.895,18.000 14.000,17.105 14.000,16.000 C14.000,16.000 14.000,13.000 14.000,13.000 C14.000,11.895 14.895,11.000 16.000,11.000 C16.000,11.000 22.000,11.000 22.000,11.000 C23.105,11.000 24.000,11.895 24.000,13.000 C24.000,13.000 24.000,16.000 24.000,16.000 Z"
      className="cls-b1" fillRule="evenodd" />
  </svg>
);
AlignRight.displayName = 'AlignRight';
/* tslint:enable */
/* eslint-enable */
