/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface StripProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Strip: React.SFC<StripProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "72" } height={ size || "72" } {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M12 26h48v1H12v-1zm0 19h48v1H12v-1z" className="p1" />
      <path d="M26 14h13v2H26v-2zm24 0h10v2H50v-2zm-24 4h20v2H26v-2zm24 0h5v2h-5v-2zM26 33h12v2H26v-2zm24 0h10v2H50v-2zm-24 4h19v2H26v-2zm24 0h5v2h-5v-2zM26 52h13v2H26v-2zm24 0h10v2H50v-2zm-24 4h20v2H26v-2zm24 0h5v2h-5v-2zm-33-6a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0-19a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0-19a5 5 0 1 1 0 10 5 5 0 0 1 0-10z"
        className="p2" />
      <path d="M21.962 55.618a5 5 0 0 1-7.31 3.798l5.481-5.252c.323-.245.805-.216 1.058.088l.771 1.366zM16 54a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm5.962-17.382a5 5 0 0 1-7.31 3.798l5.481-5.252c.323-.245.805-.216 1.058.088l.771 1.366zM16 35a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm5.962-17.382a5 5 0 0 1-7.31 3.798l5.481-5.252c.323-.245.805-.216 1.058.088l.771 1.366zM16 16a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        className="p3" />
      <path d="M18.136 59.87a4.998 4.998 0 0 1-5.44-2.325l1.471-1.376c.359-.246.9-.225 1.193.071l2.776 3.63zm0-19a4.998 4.998 0 0 1-5.44-2.325l1.471-1.376c.359-.246.9-.225 1.193.071l2.776 3.63zm0-19a4.998 4.998 0 0 1-5.44-2.325l1.471-1.376c.359-.246.9-.225 1.193.071l2.776 3.63z"
        className="p4" />
    </g>
  </svg>
);
Strip.displayName = 'Strip';
/* tslint:enable */
/* eslint-enable */
