/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface Proportions30_70Props extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Proportions30_70: React.SFC<Proportions30_70Props> = ({size, ...props}) => (
  <svg fill="currentColor" width={ size || "114" } height={ size || "51" } {...props}>
    <g transform="translate(12 15)" className="p1" fillRule="evenodd">
      <rect width="29" height="22" rx="3" />
      <rect x="31" width="59" height="22" rx="3" />
    </g>
  </svg>
);
Proportions30_70.displayName = 'Proportions30_70';
/* tslint:enable */
/* eslint-enable */
