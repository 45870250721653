/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface OfferingDefaultImageProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const OfferingDefaultImage: React.SFC<OfferingDefaultImageProps> = ({size, ...props}) => (
  <svg viewBox="0 0 54 54" fill="currentColor" width={ size || "40" } height={ size || "40" } {...props}>
    <circle cx="26.98" cy="27.03" r="27" fill="currentColor" />
    <path d="M35.5 34a.5.5 0 0 1-.45-.28L30 25.56l-3.54 5.19a.5.5 0 0 1-.79.11l-2.56-1.56-4.16 4.48a.5.5 0 1 1-.84-.54l4.5-5a.5.5 0 0 1 .77-.08l2.54 1.54 3.68-5.43A.49.49 0 0 1 30 24a.5.5 0 0 1 .43.28l5.5 9a.5.5 0 0 1-.22.67.49.49 0 0 1-.22.05M22.5 22A1.5 1.5 0 1 0 24 23.5 1.5 1.5 0 0 0 22.5 22m0 4A2.5 2.5 0 1 1 25 23.5 2.5 2.5 0 0 1 22.5 26"
      fill="currentColor" />
    <rect x="17.5" y="18.5" width="19" height="16" rx="2" ry="2" fill="none" stroke="currentColor" />
  </svg>
);
OfferingDefaultImage.displayName = 'OfferingDefaultImage';
/* tslint:enable */
/* eslint-enable */
